<div class="container-fluid ea-seller">
    <div class="row main-content">
        <div class="col-sm-12 col-md-12 col-lg-6 title-content">
            <div class="title-header">
                Become an EnableAll seller
            </div>
            <div class="title-text">
                Selling your products with EnableAll couldn't be simpler. We've made the process as easy, fast and intuitive as we can. Watch our short video to see how it's done and click on the Contact Us button to register as a seller.
            </div>
            <div class="title-get-started d-flex justify-content-start">
                <a
                  href="javascript:void(0)"
                  class="ea-button ea-button-secondary focus-ring justify-content-around"
                >      
                  Get Started
                </a>
              </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 media-content">
            <div class="title-media-block">
                <iframe class="title-media" [src]="safeUrl" frameborder="0" allowfullscreen></iframe>
            </div>
        </div>
    </div>

    <div class="row ea-seller-pros">
        <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="ea-seller-pros-div">
                <div class="ea-seller-pros-div-inner">
                    <div class="seller-pros-circle"></div>
                    <div class="seller-pros-title">
                        Reach millions
                    </div>
                    <div class="seller-pros-text">
                        open up a whole new market of previously unserved consumers through EnableAll's unique interface, designed with Accessibility a it's core
                    </div>
                </div>                
            </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="ea-seller-pros-div">
                <div class="ea-seller-pros-div-inner">
                    <div class="seller-pros-circle"></div>
                    <div class="seller-pros-title">
                        Break Boundaries
                    </div>
                    <div class="seller-pros-text">
                        open up a whole new market of previously unserved consumers through EnableAll's unique interface, designed with Accessibility a it's core
                    </div>
                </div>                
            </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4">
            <div class="ea-seller-pros-div">
                <div class="ea-seller-pros-div-inner">
                    <div class="seller-pros-circle"></div>
                    <div class="seller-pros-title">
                        Increase Sales
                    </div>
                    <div class="seller-pros-text">
                        open up a whole new market of previously unserved consumers through EnableAll's unique interface, designed with Accessibility a it's core
                    </div>
                </div>                
            </div>
        </div>
    </div>

    <div class="row ea-seller-business">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="seller-business-div">
                <div class="seller-business-title">
                    Simple and secure commerce for all
                </div>
                <div class="seller-business-header">
                    Listing with EnableAll is simple, fast and easy to manage. We've made it as easy as we can for consumers to shop, and retailers and brands to list with us.
                </div>
                <div class="seller-business-divider">

                </div>
                <div class="seller-business-points">
                    <div class="seller-business-points-left">
                        <div class="seller-business-circle"></div>
                    </div>
                    <div class="seller-business-points-right">
                        <div class="seller-business-line-header">
                            Integrate with your existing platform
                        </div>
                        <div class="seller-business-line-text">
                            EnableAll can integrate with most ecommerce platforms, including Shopify, WooCommerce, Big Commerce, Linnworks and more.
                        </div>
                    </div>
                </div>
                <div class="seller-business-points">
                    <div class="seller-business-points-left">
                        <div class="seller-business-circle"></div>
                    </div>
                    <div class="seller-business-points-right">
                        <div class="seller-business-line-header">
                            No setup fee
                        </div>
                        <div class="seller-business-line-text">
                            Setup is free &easy, and our service team will walk you through the simple 3 step process of becoming an EnableAll retailer.
                        </div>
                    </div>
                </div>
                <div class="seller-business-points">
                    <div class="seller-business-points-left">
                        <div class="seller-business-circle"></div>
                    </div>
                    <div class="seller-business-points-right">
                        <div class="seller-business-line-header">
                            Low transaction fees
                        </div>
                        <div class="seller-business-line-text">
                            Our standard commission rate is 10% for each sale, plus the usual vat and credit card fees, making us one of the cheapest marketplaces around.
                        </div>
                    </div>
                </div>
                <div class="seller-business-points">
                    <div class="seller-business-points-left">
                        <div class="seller-business-circle"></div>
                    </div>
                    <div class="seller-business-points-right">
                        <div class="seller-business-line-header">
                            Same day shipment payment
                        </div>
                        <div class="seller-business-line-text">
                            Our optional integration with Stripe payments means that you can have your money paid out same day, if you choose.
                        </div>
                    </div>
                </div>

                <div class="seller-bussiness-get-started">
                    <a
                        href="javascript:void(0)"
                        class="ea-button ea-button-secondary focus-ring justify-content-around"
                    >      
                      Get Started
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="ea-retailers">
        <div class="ea-retailers-title">
            Brands and retailers already benefitting from EnableAll
        </div>
        <div class="ea-retailers-logos">
            <div class="ea-retailers-logo">
                <img src="https://www.next.co.uk/static-content/icons/header/next/v2/vision/new-next-black-logo.svg" alt="Snow">
              </div>
              <div class="ea-retailers-logo">
                <img src="https://www.sportsdirect.com/images/sportsdirect-2021.svg" alt="Forest">
              </div>
              <div class="ea-retailers-logo">
                <img src="https://www.uspoloassn.co.uk/cdn/shop/files/uspoloassn-dhm-header_800x_493b6061-a88d-4ec3-b5aa-99423b056c87.jpg?v=1613696216&width=1200" alt="Mountains">
              </div>
        </div>
    </div>

    <div class="row ea-seller-testimonies">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="ea-seller-testimonies-div">
                <div class="ea-seller-testimony">
                    <span class="material-icons ea-quote-icon">
                        format_quote
                    </span>
                    <div class="ea-seller-testimony-text">
                        Linking my Shopify website to their store was straightforward and best of all the staff were able to talk me through the setup process and were really helpful and friendly.
                    </div>
                    <div class="ea-seller-testimony-author">
                        Amanda Alexander,
                    </div>
                    <div class="ea-seller-testimony-vendor">
                        Giddy Goat Toys
                    </div>
                </div>
            </div>            
        </div>
    </div>  
    
    <div class="row ea-seller-footer">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <div class="footer-left">
                            <div class="footer-left-title">
                                Start selling today
                            </div>
                            <div class="footer-left-header">
                                Selling on EnableAll allows you to reach millions whether you are selling one or millions.
                            </div>
                            <div class="footer-left-content">
                                <div class="content-number">
                                    1
                                </div>
                                <div class="content-text">
                                    Complete the form
                                </div>
                            </div>
                            <div class="footer-left-content">
                                <div class="content-number">
                                    2
                                </div>
                                <div class="content-text">
                                    Our team will get in touch and answer any questions
                                </div>
                            </div>
                            <div class="footer-left-content">
                                <div class="content-number">
                                    3
                                </div>
                                <div class="content-text">
                                    You will be invited to join the platform
                                </div>
                            </div>
                            <div class="footer-left-content">
                                <div class="content-number">
                                    4
                                </div>
                                <div class="content-text">
                                    Away you go..
                                </div>
                            </div>
                        </div>                    
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <div class="footer-right">
                            <div class="enquiry-form">
                                <div class="enquiry-form-title">
                                    Become a Seller
                                </div>
                                <div class="enquiry-form-header">
                                    Complete the form below and a member of our team will be in touch to discuss joining the EnableAll platform within 48 hours
                                </div>
                                <form [formGroup]="enquiryForm" #form="ngForm">
                                    <fieldset>
                                        <div class="ea-form-field">
                                            <label class="ea-label required" for="first-name-input"
                                              >First name</label
                                            >
                                            <input
                                              type="text"
                                              id="fname"
                                              name="firstname"
                                              formControlName="firstname"
                                              class="ea-input"
                                              placeholder="First Name"
                                              alt="first name input"
                                            />
                                          </div>
                                          <div class="ea-form-field">
                                            <label class="ea-label required" for="last-name-input"
                                              >Last name</label
                                            >
                                            <input
                                              type="text"
                                              id="lname"
                                              name="lastname"
                                              formControlName="lastname"
                                              class="ea-input"
                                              placeholder="Last Name"
                                              alt="last name input"
                                            />
                                          </div>
                                          <div class="ea-form-field">
                                            <label class="" for="email-input">URL</label>
                                            <input
                                              type="text"
                                              id="url"
                                              name="url"
                                              formControlName="url"
                                              class="ea-input"
                                              placeholder="URL"
                                              alt="url input"
                                            />
                                          </div>
                                          <div class="ea-form-field">
                                            <label class="ea-label required" for="email-input">Email</label>
                                            <input
                                              type="text"
                                              id="emailAddress"
                                              name="email"
                                              formControlName="email"
                                              class="ea-input"
                                              placeholder="Email address"
                                              alt="email input"
                                            />
                                          </div>
                                          <div class="ea-form-field">
                                            <label class="ea-label required" for="email-input">Phone</label>
                                            <input type="text" id="phone" name="phone" formControlName="phone" class="ea-input" placeholder="Phone" alt="phone input" />
                                          </div>

                                          <div class="newsLetterCheckbox form-group form-check mb-4">
                                            <div class="chkbox-control">
                                                <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    formControlName="newsletterSignup"
                                                    value=""
                                                    id="checkbox"
                                                    checked
                                                    alt="newsletter agreement checkboxes"
                                                /> 
                                            </div>
                                            <div>
                                                <label class="form-check-label" for="checkbox">
                                                    I agree to receive communications from EnableAll and consent to the
                                                    storage of my data according to our privacy policy
                                                </label>
                                            </div>
                                          </div>

                                          <button
                                            type="submit"
                                            value="GetStarted"
                                            class="ea-button ea-button-secondary focus-ring justify-content-around"
                                            title=""
                                            [disabled]="!enquiryForm.valid"
                                            (click)="submitForm()"
                                          >
                                            Get Started
                                          </button>
                                          
                                    </fieldset>
                                </form>
                            </div>>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>