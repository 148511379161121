import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastService } from '@ea/services';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-seller',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterLink],
  templateUrl: './seller.component.html',
  styleUrl: './seller.component.scss'
})
export class SellerComponent {
  public enquiryForm!: FormGroup;
  videoUrl: string = 'https://youtu.be/mwUN3cBnohQ?si=AhkBb5nwDtB9D4fq';
  safeUrl: any;
  constructor(private _sanitizer: DomSanitizer,
              private formGroup: FormBuilder,
              private toast: ToastService,
              // private route: Router,
  ){
    this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
    this.enquiryForm = this.formGroup.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      url: [''],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      newsletterSignup: true,
    });
  }

  submitForm(){
    if (this.enquiryForm.valid) {
      const contact = {
        firstname: this.enquiryForm.value.firstname,
        lastname: this.enquiryForm.value.lastname,
        email: this.enquiryForm.value.email,
        message: this.enquiryForm.value.message,
      };

      console.log("Form", this.enquiryForm.value);
      const message = JSON.stringify(contact);
      const updateContact = JSON.parse(message);
      // this.contactService.postData(updateContact).subscribe(() => {
      //   this.toast.show(
      //     `Thank you ${updateContact.firstname}. You're form has been submitted successfully!`
      //   );
      //   this.route.navigate(['/']);
      // });
      this.enquiryForm.reset();
      this.enquiryForm.patchValue({
        newsletterSignup: true
      });
    }
  }
}
