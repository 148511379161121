<div class="container-fluid ea-about-us">
    <div class="row main-content">
        <div class="col-sm-12 col-md-12 col-lg-6 title-content">
            <div class="title-header">
                Making e-commerce accessible for everyone
            </div>
            <div class="title-text">
                We are pushing the boundaries and breaking down barriers between what disabled people want to do and what they're able to do online.
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 media-content">
            <div class="title-media-block">
                <iframe class="title-media" [src]="safeUrl" frameborder="0" allowfullscreen></iframe>
            </div>
        </div>
    </div>

    <div class="row brand-promise">
        <div class="brand-promise-header">Our brand promise</div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="brand-promise-circle"></div>
            <div class="brand-promise-text">
                Truely accessible online marketplace
            </div>           
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="brand-promise-circle"></div>
            <div class="brand-promise-text">
                Truely accessible online marketplace
            </div>           
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="brand-promise-circle"></div>
            <div class="brand-promise-text">
                Truely accessible online marketplace
            </div>           
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3">
            <div class="brand-promise-circle"></div>
            <div class="brand-promise-text">
                Truely accessible online marketplace
            </div>           
        </div>
    </div>
    <div class="row why-we-exist">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="why-we-exist-title">
                Why we exist
            </div>
            <div class="why-we-exist-p">
                Why we exist
            </div>
        </div>
    </div>
    <div class="row our-values">
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="our-values-div">
                <div class="our-values-circle-block">
                    <div class="our-values-circle"></div>
                </div>
                <div class="our-values-text-block">
                    <div class="our-values-text-header">Our values</div>
                    <div class="our-values-text-content">
                        Our Values text here to describe more about it
                    </div>
                    <div class="read-more-block">
                        <div class="our-values-read-more">
                            Read More 
                        </div>
                        <span class="read-more-triangle_down"></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="our-values-div">
                <div class="our-values-circle-block">
                    <div class="our-values-circle"></div>
                </div>
                <div class="our-values-text-block">
                    <div class="our-values-text-header">Sell on EnableAll</div>
                    <div class="our-values-text-content">
                        Selling with EnableAll is very easy and you help us to help both disabled and non-disabled to shop with freedom.
                    </div>
                    <div class="read-more-block">
                        <div class="our-values-read-more">
                            Read More 
                        </div>
                        <span class="read-more-triangle_down"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>