import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss'
})
export class AboutUsComponent {
  videoUrl: string = 'https://youtu.be/mwUN3cBnohQ?si=AhkBb5nwDtB9D4fq';
  safeUrl: any;
  constructor(private _sanitizer: DomSanitizer){
    this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoUrl);
  }
}
