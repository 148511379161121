import { Routes } from '@angular/router';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { SellerComponent } from './components/seller/seller.component';

export const AppRoutes: Routes = [
  {
    path: 'product-details',
    loadChildren: () =>
      import(
        /* webpackChunkName: "product-details-routes" */ './modules/products/products-routing.module'
      ).then((x) => x.ProductRoutes),
  },
  {
    path: 'category',
    loadChildren: () =>
      import(
        /* webpackChunkName: "category-page-routes" */ './modules/category/category-routing.module'
      ).then((x) => x.CategoryRoutes),
  },
  {
    path: 'vendors',
    loadChildren: () =>
      import(
        /* webpackChunkName: "category-page-routes" */ './modules/vendors/vendor-routing.module'
      ).then((x) => x.VendorRoutes),
  },
  {
    path: '',
    loadChildren: () =>
      import(
        /* webpackChunkName: "search-result-routes" */ './modules/search/search-routing.module'
      ).then((x) => x.SearchRoutes),
  },
  // {
  //   path: 'account',
  //   loadChildren: () => import('@ea/account').then((r) => r.ACCOUNT_ROUTES),
  // },
  {
    path: 'about-us', component: AboutUsComponent
  },
  {
    path: 'seller', component: SellerComponent
  },
];
